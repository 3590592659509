import { Pipe, PipeTransform } from '@angular/core';
import { UserBase } from '@wndr/common/core/models/user-base';

/** Full-name pipe. */
@Pipe({
	name: 'wndrcFullName',
	standalone: true,
})
export class FullNamePipe implements PipeTransform {

	/** @inheritdoc */
	public transform(data: UserBase.FullName): string {
		return UserBase.toDetailedName(data);
	}
}
