import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Provider } from '@angular/core';
import { DownloadService } from '@wndr/common/core/services/download.service';
import { map, Observable, tap } from 'rxjs';
import { saveAs } from 'file-saver';

/** Web download service. */
@Injectable()
export class WebDownloadService extends DownloadService {
	private readonly httpClient = inject(HttpClient);

	/**
	 * Downloads file from provided url.
	 * @param url Url to file.
		* @param fileName File name.
	 */
	public downloadFile(url: string, fileName: string): Observable<void> {
		return this.httpClient.get(url, {
			responseType: 'blob',
		}).pipe(
			tap(blob => saveAs(blob, fileName)),
			map(() => undefined),
		);
	}
}

/** Create provider for a web download service. */
export function provideWebDownloadService(): Provider {
	return {
		provide: DownloadService,
		useClass: WebDownloadService,
	};
}
