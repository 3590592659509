import { z } from 'zod';

import { userRatingSchema } from './user-rating';

/** User base validation schema. */
export const userBaseSchema = z.object({
	id: z.number(),
	avatarUrl: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	preferredName: z.string().nullable(),
	initials: z.string().nullable(),
	username: z.string(),
	entity: z.string(),
	pronoun: z.string(),
	clinicianType: z.string(),
	email: z.string(),
	specialty: z.array(z.string()),
	hasContact: z.boolean(),
	rating: userRatingSchema.nullable(),
});

/** Base user. */
export type UserBase = Readonly<z.infer<typeof userBaseSchema>>;

export namespace UserBase {

	/** User's full name shortcut type.  */
	export type FullName = Pick<UserBase, 'firstName' | 'lastName' | 'preferredName' | 'initials'>;

	/**
	 * Get user's full name.
	 * @param userNames User first and last names.
	 */
	export function toFullName(userNames: { firstName: string; lastName: string; }): string {
		return `${userNames.firstName} ${userNames.lastName}`;
	}

	/**
		* Generates detailed name using preferred user's name and their initials.
		* @param param0 Object with full name data.
		*/
	export function toDetailedName({ firstName, lastName, preferredName, initials }: FullName): string {
		const nameParts = [
			...(preferredName ? [preferredName] : [firstName]),
			...(initials ? [initials] : []),
			lastName,
		] as const;

		return nameParts.join(' ');
	}

	/**
		* Compare two users.
		* @param user User.
		* @param anotherUser Another user.
		*/
	export function compare(user?: UserBase, anotherUser?: UserBase): boolean {
		return user?.id === anotherUser?.id;
	}
}
