import { Injectable } from '@angular/core';
import {
	catchError,
	defer,
	EMPTY,
	forkJoin,
	map,
	merge,
	Observable,
	ReplaySubject,
	startWith,
	Subject,
	switchMap,
	tap,
	throwError,
} from 'rxjs';

import { AppError } from '../models/app-error';

/**
	* Util function to wrap source observable with refresh feature.
	* @param refresh Refresh observable.
	* @param source Source observable.
	*/
export function withRefresh<T>(
	refresh: Observable<RefreshOutput>,
	source: () => Observable<T>,
): Observable<T> {
	return refresh.pipe(
		switchMap(({ resolve, reject }) => source().pipe(
			tap(() => resolve()),
			catchError((e: unknown) => {
				/*
					Not to swallow error because we already had a few cases when list is not rendered
					but there is nothing in console.
				*/
				console.error(e);
				reject();

				// Don't throw an error because then the stream will end and there will be no further updates.
				return EMPTY;
			}),
		)),
	);
}

type RefreshOutput = Readonly<{

	/** Resolve fn. */
	resolve: () => void;

	/** Reject fn. */
	reject: () => void;
}>;

type RefreshElement = Subject<RefreshOutput>;

/**
	* Provides API for running refresh data process
	* and handling refresh data completion.
 */
@Injectable()
export class RefreshService {
	private readonly refreshElements: RefreshElement[] = [];

	/**
		* Register refresh elements for provided keys.
		* Adds registration to registration list to trigger this one later.
		* @param keys Keys.
	 */
	public registerRefreshElementsFor<K extends PropertyKey>(...keys: K[]): Record<K, { refresh$: Observable<RefreshOutput>;}> {
		const result: Record< K, { refresh$: Observable<RefreshOutput>; }
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		> = {} as any;

		for (const key of keys) {
			result[key] = {
				refresh$: this.registerRefreshElement(),
			};
		}

		return result;
	}

	/** Run refresh process. */
	public refresh(): Observable<void> {
		const refreshed$ = this.refreshElements.map(r => {
			const refreshElementCompleted$ = new ReplaySubject<void>(1);
			const refreshElementFailed$ = new ReplaySubject<void>(1);

			const resolve = (): void => {
				refreshElementCompleted$.next();
				refreshElementCompleted$.complete();
				refreshElementFailed$.complete();
			};

			const reject = (): void => {
				refreshElementFailed$.next();
				refreshElementFailed$.complete();
				refreshElementCompleted$.complete();
			};

			const rejected$ = refreshElementFailed$.pipe(
				switchMap(() => throwError(() =>
					new AppError('Something went wrong due refresh process.'))),
			);
			const completed$ = defer(() => {
				r.next({ resolve, reject });
				return refreshElementCompleted$;
			});

			return merge(rejected$, completed$);
		});

		return forkJoin(refreshed$).pipe(map(() => undefined));
	}

	/** Destroy refresh elements. */
	public destroy(): void {
		const elements = [...this.refreshElements];
		elements.forEach(r => {
			const index = this.refreshElements.indexOf(r);
			this.refreshElements.splice(index);
			r.complete();
		});
	}

	private registerRefreshElement(): Observable<RefreshOutput> {
		const refreshElement$ = new ReplaySubject<RefreshOutput>(1);
		this.refreshElements.push(refreshElement$);

		return refreshElement$.pipe(
			startWith({ resolve: () => undefined, reject: () => undefined }),
		);
	}
}
